import { cloneElement } from 'react';
import classnames from 'classnames';

import { H3 } from 'components/heading/Heading';
import { Link } from 'components/link/Link';

import s from './ListCard.module.scss';

const c = classnames.bind(s);

type Props = {
  image?: React.ReactElement;
  title: string;
  link?: string;
  children?: React.ReactNode;
  size?: number;
  offset?: boolean;
};

export const ListCard = ({
  image,
  title,
  link,
  size,
  offset,
  children,
}: Props) => {
  if (!title) {
    return null;
  }
  return (
    <div
      className={c(s.listCard, {
        [s.offset]: offset,
        [s.three]: size === 3,
        [s.four]: size === 4,
      })}
    >
      <div className={s.listCard__imageWrap}>
        {image &&
          cloneElement(image, {
            className: s.listCard__image,
          })}
      </div>
      <div className={s.listCard__content}>
        <H3 className={s.listCard__title} skipGradient>
          {link ? (
            <Link className={s.listCard__link} to={link}>
              {title}
            </Link>
          ) : (
            title
          )}
        </H3>
        {children}
      </div>
    </div>
  );
};
